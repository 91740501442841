<template>
  <v-container class="my-8">
    <h1 class="text-center pb-5">{{ $t("__chia_plan_title") }}</h1>
    <v-row justify="center">
      <v-col
        :cols="isMobile ? 12 : 6"
        v-for="(card, i) in planCard"
        :key="`card-${i}`"
      >
        <v-card :color="card.color" class="h-100">
          <v-card-title
            class="justify-center white--text font-weight-bold text-h4"
          >
            {{ card.category }}
          </v-card-title>
          <v-card-text class="d-flex justify-space-around">
            <v-card
              width="100%"
              v-for="(plan, i) in card.plans"
              class="d-flex flex-column justify-space-between"
              :class="{ 'mr-2': card.plans.length >= 2 }"
              :key="`plan-${i}`"
            >
              <v-card-title class="justify-center text-h5">{{
                plan.title
              }}</v-card-title>
              <v-card-text class="text-center text-h6">{{
                plan.subtitle
              }}</v-card-text>
              <v-card-actions>
                <v-btn block color="primary" @click="toRegister(plan.id)">{{
                  $t("__chia_plan_check_detail")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="isMobile ? 12 : 7">
        <v-card :color="planCardNgo.color">
          <v-card-title
            class="justify-center white--text font-weight-bold text-h4"
          >
            {{ planCardNgo.category }}
          </v-card-title>
          <v-card-text
            class="d-flex justify-space-around"
            :class="{ 'flex-column': isMobile }"
          >
            <v-card
              width="100%"
              v-for="(plan, i) in planCardNgo.plans"
              class="d-flex flex-column justify-space-between"
              :class="{
                'mr-2': planCardNgo.plans.length >= 2,
                'mb-3': isMobile
              }"
              :key="`plan-${i}`"
            >
              <v-card-title class="justify-center text-h5">{{
                plan.title
              }}</v-card-title>
              <v-card-text class="text-center text-h6">{{
                plan.subtitle
              }}</v-card-text>
              <v-card-actions>
                <v-btn block color="primary" @click="toRegister(plan.id)">{{
                  $t("__chia_plan_check_detail")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Price",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  data() {
    return {
      planCard: [
        {
          category: this.$t("__chia_plan_personal"),
          plans: [
            {
              title: this.$t("__chia_plan_free_title"),
              subtitle: this.$t("__chia_plan_free_subtitle"),
              id: "personal-free"
            },
            {
              title: this.$t("__chia_plan_regular_title"),
              subtitle: this.$t("__chia_plan_personal_regular_subtitle"),
              id: "personal-regular"
            }
          ],
          color: "success"
        },
        {
          category: this.$t("__chia_plan_business"),
          plans: [
            {
              title: this.$t("__chia_plan_free_title"),
              subtitle: this.$t("__chia_plan_free_subtitle"),
              id: "business-free"
            },
            {
              title: this.$t("__chia_plan_regular_title"),
              subtitle: this.$t("__chia_plan_business_regular_subtitle"),
              id: "business-regular"
            }
          ],
          color: "orange"
        }
      ],
      planCardNgo: {
        category: this.$t("__chia_plan_ngo"),
        plans: [
          {
            title: "NGO",
            subtitle: this.$t("__chia_plan_ngo_subtitle"),
            id: "ngo-plan"
          },
          {
            title: this.$t("__chia_plan_initial"),
            subtitle: this.$t("__chia_plan_initial_subtitle"),
            id: "initial-plan"
          },
          {
            title: this.$t("__chia_plan_chia_brand"),
            subtitle: this.$t("__chia_plan_chia_brand_subtitle"),
            id: "chia-brand"
          }
        ],
        color: "grey"
      }
    };
  },
  methods: {
    toRegister(id) {
      this.$router.push({ name: "PriceIntro", params: { id } });
    }
  }
};
</script>
